import React from "react";

import Link from "./Link";

import nftCalendarLogo from "../../static/nft-calendar.svg";

const Footer: React.FC = () => (
  <footer>
    <div className="footer">
      <Link url={"https://opensea.io/collection/nftisnotavailable"}>
        OpenSea
      </Link>
      <Link url={"https://twitter.com/nft_isnt_avlbl"}>Twitter</Link>
      <Link url={"https://www.instagram.com/nft_isnt_avlbl"}>Instagram</Link>
      <Link url={"https://t.me/addstickers/NFT_ISNT_AVLBL"}>Telegram</Link>
    </div>
    <div style={{ textAlign: "center" }}>
      <Link url={"https://nftcalendar.io/event/nft-is-not-available"}>
        <img style={{ width: 100, marginBottom: 15 }} src={nftCalendarLogo} />
      </Link>
    </div>
  </footer>
);

export default Footer;
