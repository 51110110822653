import React, { useEffect, useRef } from "react";
import Jazzicon from "@metamask/jazzicon";

type IdenticonProps = {
  account: string;
};

const Identicon: React.FC<IdenticonProps> = ({ account }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = "";
      ref.current.appendChild(Jazzicon(35, parseInt(account.slice(2, 10), 16)));
    }
  }, [account]);

  return <div className="identicon" ref={ref} />;
};

export default Identicon;
