import React from "react";
import Identicon from "./Identicon";

import { useWeb3Context } from "../contexts/web3";
import ChainSelector from "./ChainSelector";
import { initMetamask } from "../utils/ethereum";

const Header: React.FC = () => {
  const { state, setState } = useWeb3Context();

  const handleConnectWallet = () => {
    initMetamask()
      .then((wallet) => {
        setState({
          isMetamaskAvailable: true,
          ...wallet,
        });
      })
      .catch(console.error);
  };

  return (
    <div className="header">
      {state.account ? (
        <div className="button-container">
          <div className="account-container">
            <Identicon account={state.account} />
            <span
              style={{
                marginLeft: 10,
                marginRight: 10,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              {state.account.slice(0, 5)}...{state.account.slice(-4)}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="line-break"></div>
      {state.isMetamaskAvailable ? (
        <></>
      ) : (
        <div className="button-container">
          <button onClick={handleConnectWallet}>CONNECT WALLET</button>
        </div>
      )}
      {state.network && state.network.chainId ? (
        <ChainSelector chainId={state.network.chainId} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Header;
