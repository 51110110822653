import React from "react";

import Link from "./Link";

import externalLink from "../../static/external-link-icon-red.svg";

const urls: Record<string, Record<string, string>> = {
  maticmum: {
    opensea: "https://testnets.opensea.io/assets/mumbai",
    etherscan: "https://mumbai.polygonscan.com/tx",
  },
  matic: {
    opensea: "https://opensea.io/assets/matic",
    etherscan: "https://polygonscan.com/tx",
  },
};

type Props = {
  code: number;
  message: string;
  data?: any;
  transaction?: {
    hash: string;
  };
};

const ErrorMessage = ({ code, message, data, transaction }: Props) => (
  <div className="message-container error">
    <span>Code: {code}</span>
    <span>{message}</span>
    {data && data.message ? <span>{data.message}</span> : <></>}
    {transaction && transaction.hash ? (
      <span className="error">
        Transaction Hash:{" "}
        <Link
          color="red"
          url={`${urls["matic"].etherscan}/${transaction.hash}`}
        >
          <div>
            <span className="error">
              {transaction.hash}
              <div className="external-link">
                <img className="external-link-icon" src={externalLink} />
              </div>
            </span>
          </div>
        </Link>
      </span>
    ) : (
      <></>
    )}
  </div>
);

export default ErrorMessage;
