import React from "react";

const Link: React.FC<{
  url: string;
  children: React.ReactNode;
  color?: string;
}> = ({ url, color = "white", children }) => (
  <a style={{ color }} href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

export default Link;
