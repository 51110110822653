import React, { useState, useContext } from "react";
import { ethers } from "ethers";

type Web3State = {
  isMetamaskAvailable: boolean;
  provider: null | ethers.providers.Web3Provider;
  network: null | ethers.providers.Network;
  account: null | string;
};

const initialState: Web3State = {
  isMetamaskAvailable: false,
  provider: null,
  network: null,
  account: null,
};

const Web3Context = React.createContext<
  | {
      state: Web3State;
      setState: (state: Web3State) => void;
    }
  | undefined
>(undefined);

export const useWeb3Context = () => {
  const context = useContext(Web3Context);
  if (context === undefined) {
    throw new Error("useWeb3Context must be used within a Web3Provider");
  }
  return context;
};

export const Web3Provider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);
  const value = { state, setState };

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
};
