import React from "react";
import "./styles.css";
import { useStaticQuery, graphql } from "gatsby";
import { Web3Provider } from "../contexts/web3";

import Seo from "../components/Seo";
import Mint from "../components/Mint";
import Frame from "../components/Frame";
import Header from "../components/Header";
import Disclaimer from "../components/Disclaimer";
import Footer from "../components/Footer";

const query = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
        lang
        country
        text
        textAlign
        direction
      }
    }
  }
`;

const IndexPage = () => {
  const { site } = useStaticQuery(query);

  return (
    <Web3Provider>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Header />
          <main>
            <Seo {...site.siteMetadata} />
            <title>
              THIS WEBSITE IS NOT AVAILABLE IN YOUR COUNTRY OR REGION
            </title>
            <div className="container">
              <Frame
                text={site.siteMetadata.text}
                lang={site.siteMetadata.lang}
                direction={site.siteMetadata.direction}
                textAlign={site.siteMetadata.textAlign}
              />
              <div className="right-container">
                <Disclaimer />
                <Mint region={site.siteMetadata.country} />
              </div>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    </Web3Provider>
  );
};

export default IndexPage;
