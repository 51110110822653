import React from "react";

const Disclaimer: React.FC = () => (
  <div className="disclaimer-container">
    <h1 style={{ marginTop: 0, marginBlockEnd: "0.6em" }}>DISCLAIMER</h1>
    <div className="disclaimer-text">
      <span>
        WE ARE INDEPENDENT ARTISTS WHO WANT TO DEMONSTRATE THE GLOBAL PROBLEM OF
        REGIONAL CONTENT RESTRICTIONS. WE SEE THE INTERNET AS IT SHOULD BE:
        FREE, AVAILABLE, WITHOUT ANY CENSORSHIP AND RESTRICTIONS. WE SINCERELY
        BELIEVE THAT WEB3 AND DECENTRALIZATION WILL HELP US TO ACHIEVE A BRIGHT
        FUTURE.
      </span>
      <span>This collection represents our reality.</span>
      <span>The reality of restrictions.</span>
    </div>
  </div>
);

export default Disclaimer;
