import React from "react";
import GlitchClip from "react-glitch-effect/core/GlitchClip";

type FrameProps = {
  text: string;
  lang: string;
  direction: any;
  textAlign: any;
};

const Frame: React.FC<FrameProps> = ({ text, lang, direction, textAlign }) => {
  return (
    <div
      lang={lang}
      className="not-available"
      style={{
        direction,
        textAlign,
      }}
    >
      <GlitchClip duration={10000}>{text}</GlitchClip>
    </div>
  );
};

export default Frame;
