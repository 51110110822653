import { ethers } from "ethers";

import ARTWORK_CONTRACT from "../../contract.json";

const initMetamask = () =>
  new Promise(async (resolve, reject) => {
    if (window.ethereum) {
      console.info("MetaMask is installed");
      const [defaultAccount] = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      console.info({ defaultAccount });
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      const network = await provider.getNetwork();
      const balance = await provider.getBalance(defaultAccount);

      const ethBalance = ethers.utils.formatEther(balance);

      resolve({
        network,
        provider,
        account: defaultAccount,
        ethBalance,
      });
    } else {
      reject(new Error("MetaMask is not installed"));
    }
  });

const changeNetworkRequest = async (network: { chainId: string }) => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: network.chainId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [network],
          });
        } catch (addError) {
          console.error(addError);
        }
      }
      console.error(switchError);
    }
  }
};

const getMintedPerRegion = async (region: string, network: string) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const artworkContract = new ethers.Contract(
    ARTWORK_CONTRACT[network],
    ARTWORK_CONTRACT.abi,
    signer
  );

  const alreadyMinted = await artworkContract.getMintedByRegion(region);

  return alreadyMinted.toNumber();
};

const mint = async (region: string, network: string) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const artworkContract = new ethers.Contract(
    ARTWORK_CONTRACT[network],
    ARTWORK_CONTRACT.abi,
    signer
  );

  const alreadyMinted = await artworkContract.getMintedByRegion(region);

  console.info({ alreadyMinted: alreadyMinted.toNumber() });

  const override = {
    value: ethers.utils.parseEther("1.0").mul(alreadyMinted),
  };

  // add lang, override
  return artworkContract.mint(region, override);
};

export { changeNetworkRequest, initMetamask, mint, getMintedPerRegion };
