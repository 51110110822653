import { ethers } from "ethers";
import React from "react";

import Link from "./Link";

import externalLink from "../../static/external-link-icon.svg";
import regions from "../../regions";

interface SuccessMessageProps extends ethers.ContractReceipt {
  hash: string;
  status: number;
  region: string;
  statusText: string;
  network: string;
}

const urls: Record<string, Record<string, string>> = {
  maticmum: {
    opensea: "https://testnets.opensea.io/assets/mumbai",
    etherscan: "https://mumbai.polygonscan.com/tx",
  },
  matic: {
    opensea: "https://opensea.io/assets/matic",
    etherscan: "https://polygonscan.com/tx",
  },
};

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  hash,
  from,
  to,
  events,
  region,
  statusText,
  network,
}) => {
  let tokenId = "0";
  if (events) {
    const [event] = events;
    if (event && event.args && event.args[3]) {
      tokenId = event.args[3].toString();
    } else {
      tokenId = regions.indexOf(region).toString() || "0";
    }
  }

  if (!urls[network]) {
    return <></>;
  }

  return (
    <div
      className={`message-container${
        statusText === "Pending" ? " active " : " "
      }success`}
    >
      <div>
        <span>Status: {statusText}</span>
      </div>
      <span style={{ display: "flex", flexDirection: "row", gap: 5 }}>
        {statusText === "Success" ? (
          <>
            VIEW ON{""}
            <Link url={`${urls[network].opensea}/${to}/${tokenId}`}>
              <div style={{ display: "flex", gap: "2px" }}>
                <span>OPENSEA</span>
                <div className="external-link">
                  <img src={externalLink} />
                </div>
              </div>
            </Link>
          </>
        ) : (
          <></>
        )}
      </span>
      <span>
        Transaction Hash:{" "}
        <Link url={`${urls[network].etherscan}/${hash}`}>
          <div>
            <span>
              {hash}
              <div className="external-link">
                <img className="external-link-icon" src={externalLink} />
              </div>
            </span>
          </div>
        </Link>
      </span>
      <span>From: {from}</span>
      <span>To: {to}</span>
    </div>
  );
};

export default SuccessMessage;
