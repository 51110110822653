import React, { useState } from "react";
import { changeNetworkRequest } from "../utils/ethereum";

const networkMap = {
  ETHEREUM_MAINNET: {
    name: "Ethereum",
    id: 1,
    network: {
      chainId: "0x1", // 1
      chainName: "Ethereum Mainnet",
      nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
      rpcUrls: [
        "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      ],
      blockExplorerUrls: ["https://mainnet.etherscan.io"],
    },
  },
  RINKEBY_TESTNET: {
    name: "Rinkeby",
    id: 4,
    network: {
      chainId: "0x4", // 4
      chainName: "Rinkeby Test Network",
      nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
      rpcUrls: [
        "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      ],
      blockExplorerUrls: ["https://rinkeby.etherscan.io"],
    },
  },
  POLYGON_MAINNET: {
    name: "Matic",
    id: 137,
    network: {
      chainId: "0x89", // 137
      chainName: "Polygon Mainnet",
      nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
      rpcUrls: ["https://polygon-rpc.com"],
      blockExplorerUrls: ["https://www.polygonscan.com/"],
    },
  },
  MUMBAI_TESTNET: {
    name: "Polygon Mumbai",
    id: 80001,
    network: {
      chainId: "0x13881", // 80001
      chainName: "Matic(Polygon) Mumbai Testnet",
      nativeCurrency: { name: "tMATIC", symbol: "tMATIC", decimals: 18 },
      rpcUrls: [
        "https://polygon-mumbai.g.alchemy.com/v2/UCrKKRE4KKUkMiM2eLgbtnZmRvW2MS9P",
      ],
      blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
    },
  },
};

const CHAINS = [networkMap.POLYGON_MAINNET];
//  process.env.TESTNET
//   ? [networkMap.MUMBAI_TESTNET]
//   : [networkMap.POLYGON_MAINNET];

type DropdownProps = {
  chainId: number;
};

const ChainSelector: React.FC<DropdownProps> = ({ chainId }) => {
  const [isOpened, setIsOpen] = useState(false);
  const network = CHAINS.find((i) => i.id === chainId);

  return (
    <div className="button-container header-dropdown">
      <button
        style={{ display: "flex", justifyContent: "center", gap: 5 }}
        onClick={() => setIsOpen(!isOpened)}
      >
        <span
          style={{ marginTop: "auto", marginBottom: "auto" }}
          className="dropdown-icon"
        >
          {isOpened ? "▶" : "◀"}
        </span>
        {"   "}
        <span style={{ marginTop: "auto", marginBottom: "auto" }}>
          {network ? network.name : "Unsupported Network"}
        </span>
      </button>
      {isOpened ? (
        <>
          {CHAINS.map(({ name, network }) => (
            <button
              key={name}
              onClick={async () => {
                const res = await changeNetworkRequest(network);
                setIsOpen(false);
              }}
            >
              {name}
            </button>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChainSelector;
