import React, { useState, useEffect } from "react";
import { ethers } from "ethers";

import ErrorMessage from "../components/Error";
import SuccessMessage from "../components/Success";

import {
  mint,
  getMintedPerRegion,
  changeNetworkRequest,
} from "../utils/ethereum";
import { useWeb3Context } from "../contexts/web3";

// markup

type MintProps = {
  region: string;
};

const networks = ["matic"];

const Mint: React.FC<MintProps> = ({ region }) => {
  const { state, setState } = useWeb3Context();
  const [mintState, setMintState] = useState({
    minting: false,
    result: null,
    error: null,
  });
  const [mintedByRegion, setMintedByRegion] = useState<number | null>(null);
  const isSupportedNetwork = networks.includes(state.network?.name);

  useEffect(() => {
    if (state.provider) {
      state.provider.on("network", (newNetwork, oldNetwork) => {
        // When a Provider makes its initial connection, it emits a "network"
        // event with a null oldNetwork along with the newNetwork. So, if the
        // oldNetwork exists, it represents a changing network
        console.info({ newNetwork, oldNetwork });
        if (oldNetwork) {
          setState((state) => ({
            ...state,
            network: newNetwork as ethers.providers.Network,
          }));
        }
      });
    }
    if (state.network) {
      getMintedPerRegion(region, state.network?.name)
        .then(setMintedByRegion)
        .catch(console.error);
    }
  }, [region, state.provider, state.network]);

  return (
    <div className="mint-container" style={{ display: "flex" }}>
      {mintState.error ? <ErrorMessage {...mintState.error} /> : <></>}
      {mintState.result ? (
        <SuccessMessage
          {...mintState.result}
          region={region}
          network={state.network?.name}
        />
      ) : (
        <></>
      )}

      {state.isMetamaskAvailable ? (
        <div className="mint-button-container">
          <div id="mint-button" className="button-container">
            <button
              onClick={async () => {
                if (!isSupportedNetwork) {
                  return changeNetworkRequest({ chainId: "0x89" });
                }
                setMintState({
                  minting: true,
                  result: null,
                  error: null,
                });
                mint(region, state.network?.name)
                  .then((res) => {
                    console.info(res);
                    setMintState({
                      minting: false,
                      result: { ...res, statusText: "Pending" },
                      error: null,
                    });

                    res
                      .wait()
                      .then((waitRes) => {
                        console.info(waitRes);
                        setMintState({
                          minting: false,
                          result: { ...res, ...waitRes, statusText: "Success" },
                          error: null,
                        });
                        setMintedByRegion(() => (mintedByRegion || 0) + 1);
                      })
                      .catch((error: any) => {
                        console.error(error);
                        setMintState({
                          minting: false,
                          result: null,
                          error,
                        });
                      });
                  })
                  .catch((error: any) => {
                    console.error(error);
                    setMintState({
                      minting: false,
                      result: null,
                      error,
                    });
                  });
              }}
            >
              {!isSupportedNetwork ? "CHANGE NETWORK" : "MINT ANYWAY"}
            </button>
          </div>
          {Number.isInteger(mintedByRegion) ? (
            <span style={{ marginTop: "auto", marginBottom: "auto" }}>
              MINTED IN YOUR REGION: {mintedByRegion}/100
            </span>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Mint;
